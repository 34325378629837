// ./App.jsx

// React
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

// Firebase
import { onAuthStateChanged } from 'firebase/auth';

// Chakra
import { Box, Center, Spinner, VStack } from '@chakra-ui/react';

// MdM
import { auth } from './common/firebase';
import DesignerView from './components/designers/DesignerView';
import Header from './components/Header/Header';
import Login from './components/Login/Login';

const App = () => {
  // ------------------------------ Hooks ------------------------------ //

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  
  // ------------------------------ Render ------------------------------ //

  const renderPage = () => {
    if (!user) {
      return (<Login />);
    } else {
      return (<DesignerView />);
    }
  };

  return (
    <BrowserRouter>
      <VStack width="100vw" height="100vh" bg="mdm_dark.300" spacing={0}>
        <Header loggedOut={!user}/>
        <Box flex={1} width="100%" overflow="auto">
          {loading ? ( // Show spinner while loading
            <Center height="100%">
              <Spinner size="xl" color="mdm_teal.500" />
            </Center>
          ) : (
            renderPage()
          )}
        </Box>
      </VStack>
    </BrowserRouter>
  );
}

export default App;
