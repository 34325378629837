// ./components/designers/product/ProductsListPage.jsx

// React
import React from 'react';

// MdM
import ListPage from '../../common/ListPage';

const ProductsListPage = () => (
  <ListPage
    title='Products'
    columns={[
      { fieldName: 'name', fieldHeading: 'Name', fieldType: 'string', flex: 2 },
      { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 3 },
      {
        fieldName: 'vendorName', fieldHeading: 'Vendor', fieldType: 'choice', flex: 2,
        choiceInfo: { collection: 'vendors', lookupKey: 'name', fieldsMapping: { 'vendorName': 'name', 'vendorId': 'id' } },
      },
      { fieldName: 'retailPrice', fieldHeading: 'Retail Price', fieldType: 'money', flex: 1 },
      { fieldName: 'tradePrice', fieldHeading: 'Trade Price', fieldType: 'money', flex: 1 },
      { fieldName: 'clientPrice', fieldHeading: 'Client Price', fieldType: 'money', flex: 1 },
    ]}
    columnToCompare="name"
    backButtonText='Back to Design Sourcing'
    backNavigationPath='/sourcing'
    collectionName='products'
  />
);

export default ProductsListPage;