// ./common/firebase.js

import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAm1MBALh_UoPCVwRP78WfIEkx0cuGvFxE",
  authDomain: "mdm-app-d119c.firebaseapp.com",
  projectId: "mdm-app-d119c",
  storageBucket: "mdm-app-d119c.firebasestorage.app",
  messagingSenderId: "99439745322",
  appId: "1:99439745322:web:21c92e238c5c60620e6653",
  measurementId: "G-7B5HMMQVXM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
