// ./components/common/Page.jsx

// React
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Chakra
import { Box, Button, VStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const Page = ({ children, backButtonText, backNavigationPath }) => {
  // ------------------------------ Hooks ------------------------------ //

  const navigate = useNavigate();

  // ------------------------------ Render ------------------------------ //

  return (
    <VStack h="100%" w="100%" p={4}>
      <Box w="100%" justifyContent="space-between" alignItems="center">
        <Button
          leftIcon={<ArrowBackIcon />}
          colorScheme="mdm_teal"
          variant="ghost"
          onClick={() => backNavigationPath && navigate(backNavigationPath)}
        >
          {backButtonText}
        </Button>
      </Box>
      <VStack h="100%" w="90%" mt={4} align="start">
        {children}
      </VStack>
    </VStack>
  );
};

export default Page;