// ./components/designers/clients/ClientsListPage.jsx

// React
import React from 'react';

// MdM
import ListPage from '../../common/ListPage';

const ClientsListPage = () => (
  <ListPage
    title='Clients'
    columns={[
      { fieldName: 'displayName', fieldHeading: 'Display Name', fieldType: 'string', flex: 1 },
      { fieldName: 'firstName', fieldHeading: 'First Name', fieldType: 'string', flex: 1 },
      { fieldName: 'lastName', fieldHeading: 'Last Name', fieldType: 'string', flex: 1 },
    ]}
    columnToCompare="displayName"
    backButtonText='Back to Design Clients'
    backNavigationPath='/clients'
    collectionName='clients'
  />
);

export default ClientsListPage;