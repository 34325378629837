// ./components/designers/nav/DesignProjects.jsx

// Chakra
import { Heading } from '@chakra-ui/react';

// MdM Icons
import { ClientsIcon, ProjectsIcon } from '../../../common/icons';

// MdM
import Page from '../../common/Page';
import NavGrid from '../../common/NavGrid';

const DesignClients = () => {
  // ------------------------------ Data ------------------------------ //

  const options = [
    {
      title: 'Clients',
      description: 'Browse and manage your list of clients.',
      path: '/clients/clients',
      buttonText: 'Browse Clients',
      icon: <ClientsIcon size={24} />,
    },
    {
      title: 'Projects',
      description: 'Browse and manage your list of projects.',
      path: '/clients/projects',
      buttonText: 'Browse Projects',
      icon: <ProjectsIcon size={24} />,
    },
  ];

  // ------------------------------ Render ------------------------------ //
  
  return (
    <Page backButtonText="Back to Designer Home" backNavigationPath="/">
      <Heading mb={6}>Design Clients</Heading>
      <NavGrid options={options} />
    </Page>
  );
};

export default DesignClients;
