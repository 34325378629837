// ./components/designers/projects/ProjectsListPage.jsx

// React
import React from 'react';

// MdM
import ListPage from '../../common/ListPage';

const ProjectsListPage = () => (
  <ListPage
    title='Projects'
    columns={[
      {
        fieldName: 'clientName', fieldHeading: 'Client', fieldType: 'choice', flex: 2,
        choiceInfo: { collection: 'clients', lookupKey: 'displayName', fieldsMapping: { 'clientName': 'displayName', 'clientId': 'id' } },
      },
      { fieldName: 'name', fieldHeading: 'Project Name', fieldType: 'string', flex: 2 },
      { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 4 },
    ]}
    columnToCompare="name"
    backButtonText='Back to Design Clients'
    backNavigationPath='/clients'
    collectionName='projects'
  />
);

export default ProjectsListPage;