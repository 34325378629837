// ./data/documents.js

// Firestore
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';

// MdM
import { db } from '../common/firebase';

export async function getDocuments(collectionName) {
  const snapshot = await getDocs(collection(db, collectionName));
  return snapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
}

export async function createDocument(collectionName, newDocument) {
  await addDoc(collection(db, collectionName), newDocument);
}

export async function saveDocument(collectionName, id, newDocument) {
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, newDocument);
}

export async function removeDocument(collectionName, id) {
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
}