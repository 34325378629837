// ./components/designers/products/ProductDetails.jsx

// React
import React from 'react';

// Chakra
import { Box } from '@chakra-ui/react';

// MdM

const ProductDetails = () => {
  // ------------------------------ Render ------------------------------ //

  return (<Box width="100%" height="100%" />);
}

export default ProductDetails;
