// ./components/Header/Header.jsx

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../common/firebase';

// Chakra
import {
  Box,
  HStack,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';

// Icon
import { FaSignOutAlt } from 'react-icons/fa';

// MdM
import SettingsModal from './SettingsModal';

const Header = ({ loggedOut }) => {
  const toast = useToast();
  const {
    isOpen: settingsIsOpen,
    onOpen: settingsOnOpen,
    onClose: settingsOnClose,
  } = useDisclosure();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        toast({
          title: 'Logout successful',
          description: 'You have been logged out successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: 'Error during logout',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <HStack
        width="100%"
        bg="mdm.teal_main"
        justifyContent="space-between"
        boxShadow="lg"
        position="relative"
        zIndex={2}
      >
        <Box as="img" src="/assets/MdMLongLogo64White.png" alt="Logo" height={16} p={2} />
        {!loggedOut && (
          <HStack>
            <IconButton
              icon={<SettingsIcon boxSize="24px" />}
              variant="ghost"
              aria-label="Settings"
              height="36px"
              width="36px"
              mr="8px"
              onClick={settingsOnOpen}
            />
            <IconButton
              icon={<FaSignOutAlt size="24px" />}
              variant="ghost"
              aria-label="Logout"
              height="36px"
              width="36px"
              mr="24px"
              onClick={handleLogout}
            />
          </HStack>
        )}
      </HStack>
      <SettingsModal isOpen={settingsIsOpen} onClose={settingsOnClose} />
    </>
  );
};

export default Header;