// ./components/common/ListPage.jsx

// React
import React, { useEffect, useState, useCallback } from 'react';

// Chakra
import { Box, Spinner } from '@chakra-ui/react';

// MdM
import { getDocuments, createDocument, saveDocument, removeDocument } from '../../data/documents';
import EditableList from './EditableList';
import Page from './Page';

const ListPage = ({ title, columns, columnToCompare, backButtonText, backNavigationPath, collectionName }) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  // ------------------------------ Callbacks ------------------------------ //

  const handleAddItem = useCallback((newFields) => {
    setLoading(true);
    return createDocument(collectionName, newFields)
      .then(() => getDocuments(collectionName))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName]);

  const handleSaveItem = useCallback((idToUpdate, updatedData) => {
    setLoading(true);
    return saveDocument(collectionName, idToUpdate, updatedData)
      .then(() => getDocuments(collectionName))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName]);

  const handleDeleteItem = useCallback((idToDelete) => {
    setLoading(true);
    return removeDocument(collectionName, idToDelete)
      .then(() => getDocuments(collectionName))
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    setLoading(true);
    getDocuments(collectionName)
      .then(setDocs)
      .finally(() => setLoading(false));
  }, [collectionName]);

  // ------------------------------ Render ------------------------------ //

  return (
    <Page backButtonText={backButtonText} backNavigationPath={backNavigationPath}>
      <Box w="100%" flex="1" position="relative">
        <EditableList
          title={title}
          columns={columns}
          columnToCompare={columnToCompare}
          items={docs}
          addItem={handleAddItem}
          saveItem={handleSaveItem}
          deleteItem={handleDeleteItem}
        />
        {loading && (
          <Box
            position="fixed"
            top="0"
            left="0"
            w="100vw"
            h="100vh"
            bg="rgba(0, 0, 0, 0.5)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="1000"
          >
            <Spinner size="xl" />
          </Box>
        )}
      </Box>
    </Page>
  );
};

export default ListPage;