// ./components/designers/nav/DesignSourcing.jsx

// Chakra
import { Heading } from '@chakra-ui/react';

// MdM Icons
import { VendorsIcon, ProductsIcon } from '../../../common/icons';

// MdM
import Page from '../../common/Page';
import NavGrid from '../../common/NavGrid';

const DesignSourcing = () => {
  // ------------------------------ Data ------------------------------ //

  const options = [
    {
      title: 'Vendors',
      description: 'Browse and manage your list of vendors.',
      path: '/sourcing/vendors',
      buttonText: 'Browse Vendors',
      icon: <VendorsIcon size={24} />,
    },
    {
      title: 'Products',
      description: 'Browse and manage your list of products.',
      path: '/sourcing/products',
      buttonText: 'Browse Products',
      icon: <ProductsIcon size={24} />,
    },
  ];

  // ------------------------------ Render ------------------------------ //
  
  return (
    <Page backButtonText="Back to Designer Home" backNavigationPath="/">
      <Heading mb={6}>Design Sourcing</Heading>
      <NavGrid options={options} />
    </Page>
  );
};

export default DesignSourcing;
