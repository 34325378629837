// ./components/common/DynamicSelect.jsx

// React
import React, { useEffect, useState, useCallback } from 'react';

// Chakra
import { Select } from '@chakra-ui/react';

// MdM
import { getDocuments } from '../../data/documents';

const DynamicSelect = ({ fieldHeading, value, onChange, choiceInfo }) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [options, setOptions] = useState([]);

  // ------------------------------ Callbacks ------------------------------ //

  const fetchOptions = useCallback(async () => {
    if (!choiceInfo.collection) return;
    const documents = await getDocuments(choiceInfo.collection);

    // Sort the documents alphabetically by the choiceKey
    const sortedDocuments = documents.sort((a, b) => {
      const textA = a[choiceInfo.lookupKey]?.toString().toLowerCase();
      const textB = b[choiceInfo.lookupKey]?.toString().toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setOptions(sortedDocuments);
  }, [choiceInfo]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <Select
      placeholder={`Select ${fieldHeading}`}
      value={value || ""} // value is now just a string
      onChange={(e) => {
        const selectedValue = e.target.value;
        const selectedOption = options.find(
          (option) => option[choiceInfo.lookupKey] === selectedValue
        );
        onChange(selectedOption);
      }}
    >
      {options.map((option) => (
        <option key={option[choiceInfo.lookupKey]} value={option[choiceInfo.lookupKey]}>
          {option[choiceInfo.lookupKey]}
        </option>
      ))}
    </Select>
  );
};

export default DynamicSelect;