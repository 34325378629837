// ./components/Header/Settings.jsx

// Chakra
import { HStack } from '@chakra-ui/react';

const Settings = () => {
  return (
    <HStack justifyContent="space-between" mt={4} mb={4}>
    </HStack>
  );
};

export default Settings;
