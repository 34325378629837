// ./components/common/EditableListItem.jsx

// React
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Chakra
import {
  AlertDialog, AlertDialogOverlay, AlertDialogContent,
  AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
  Button, HStack, Input, IconButton, Text, useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons';

const EditableListItem = ({ columns, fields, onSave, onDelete }) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({ ...fields });
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();


  // Refs
  const containerRef = useRef();
  const cancelRef = useRef();

  // ------------------------------ Callbacks ------------------------------ //

  const handleDoubleClick = useCallback((key) => {
    setIsEditing(true);
    // Focus and select the appropriate field
    setTimeout(() => {
      const input = document.getElementById(`editable-${key}`);
      if (input) {
        input.focus();
        input.select();
      }
    }, 0);
  }, []);

  const handleSave = useCallback(() => {
    setIsEditing(false);
    onSave(editedFields);
  }, [onSave, editedFields]);

  const handleCancel = useCallback(() => {
    setEditedFields({ ...fields });
    setIsEditing(false);
  }, [fields]);

  // ------------------------------ Effects ------------------------------ //

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && containerRef.current && !containerRef.current.contains(event.target)) {
        handleCancel(); // Cancel editing when clicking outside
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, handleCancel]);

  // ------------------------------ Render ------------------------------ //

  return (
    <>
      <HStack
        ref={containerRef}
        w="100%"
        p={2}
        borderWidth="1px"
        borderRadius="md"
        alignItems="center"
        spacing={4}
      >
        {/* Render fields dynamically */}
        {columns
          .map((column) => (
            isEditing ? (
              <Input
                key={column.fieldName}
                id={`editable-${column.fieldName}`}
                value={editedFields[column.fieldName]}
                onChange={(e) =>
                  setEditedFields({ ...editedFields, [column.fieldName]: e.target.value })
                }
                onKeyDown={(e) => e.key === 'Enter' && handleSave()}
                fontSize="md"
                flex={column.flex}
              />
            ) : (
              <Text
                key={column.fieldName}
                fontSize="md"
                noOfLines={1}
                onDoubleClick={() => handleDoubleClick(column.fieldName)}
                cursor="pointer"
                flex={column.flex}
                color={fields[column.fieldName] ? 'white': 'mdm.medium'}
              >
                {fields[column.fieldName] ? fields[column.fieldName] : `[Enter ${column.fieldName}]`}
              </Text>
            )
          ))
        }

        {/* Action Buttons */}
        {isEditing ? (
          <HStack h="100%">
            <IconButton
              aria-label="Save"
              icon={<CheckIcon />}
              colorScheme="green"
              onClick={handleSave}
            />
            <IconButton
              aria-label="Cancel"
              icon={<CloseIcon />}
              colorScheme="gray"
              onClick={handleCancel}
            />
          </HStack>
        ) : (
          <HStack h="100%">
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              colorScheme="blue"
              onClick={() => setIsEditing(true)}
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={onDeleteAlertOpen}
            />
          </HStack>
        )}
      </HStack>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="mdm.dark">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Item
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this item?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => {
                onDelete();
                onDeleteAlertClose();
              }} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditableListItem;