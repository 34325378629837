// ./components/designers/vendors/VendorDetails.jsx

// React
import React from 'react';

// Chakra
import { Box } from '@chakra-ui/react';

// MdM

const VendorDetails = () => {
  // ------------------------------ Render ------------------------------ //

  return (<Box width="100%" height="100%" />);
}

export default VendorDetails;
