// ./components/designers/vendors/VendorsListPage.jsx

// React
import React from 'react';

// MdM
import ListPage from '../../common/ListPage';

const VendorsListPage = () => (
  <ListPage
    title='Vendors'
    columns={[
      { fieldName: 'name', fieldHeading: 'Name', fieldType: 'string', flex: 1 },
      { fieldName: 'description', fieldHeading: 'Description', fieldType: 'string', flex: 2 },
    ]}
    columnToCompare="name"
    backButtonText="Back to Design Sourcing"
    backNavigationPath="/sourcing"
    collectionName='vendors'
  />
);

export default VendorsListPage;