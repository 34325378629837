// ./components/common/EditableList.jsx

// React
import React, { useCallback, useRef, useState } from 'react';

// Chakra
import {
  Box, Button, Heading, HStack, IconButton, Input,
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, ModalFooter,
  Text, VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

// MdM
import DynamicSelect from './DynamicSelect';
import EditableListItem from './EditableListItem';

const EditableList = ({ title, columns, columnToCompare, items, addItem, saveItem, deleteItem }) => {
  // ------------------------------ Hooks ------------------------------ //

  // State
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newFields, setNewFields] = useState({});
  const [search, setSearch] = useState('');

  // Refs
  const nameInputRef = useRef(null);

  // ------------------------------ Callbacks ------------------------------ //

  const handleOpenModal = useCallback(() => {
    setIsAddModalOpen(true);
    setTimeout(() => nameInputRef.current?.focus(), 0);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsAddModalOpen(false);
    setNewFields({});
  }, []);

  const handleAddItem = useCallback(() => {
    addItem(newFields);
    handleCloseModal();
  }, [addItem, handleCloseModal, newFields]);

  const handleSaveItem = useCallback((id) => (updatedData) => {
    saveItem(id, updatedData);
  }, [saveItem]);

  const handleDeleteItem = useCallback((id) => () => {
    deleteItem(id);
  }, [deleteItem]);

  // ------------------------------ Render ------------------------------ //

  return (
    <VStack w="100%" h="100%" align="start">
      {/* Header Row */}
      <HStack w="100%" mb={2} justifyContent="space-between" alignItems="center">
        <Heading size="xl">{title}</Heading>
        <Input
          ml={8}
          mr={8}
          placeholder="Search items..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          flex={1}
        />
        <IconButton
          aria-label="Add new item"
          icon={<AddIcon />}
          colorScheme="teal"
          onClick={handleOpenModal}
        />
      </HStack>

      <VStack w="100%" flex={1} spacing={0} align="start">
        {/* Column Headings */}
        <HStack
          w="100%"
          p={2}
          pl={4}
          pr={4}
          bg="mdm.dark_medium"
          borderWidth="1px"
          borderRadius="md"
          alignItems="center"
          spacing={4}
        >
          {columns
            .map(({ fieldName, fieldHeading, flex }) => (
              <Text
                key={fieldName}
                fontSize="md"
                flex={flex || 1}
                fontWeight="bold"
                noOfLines={1}
              >
                {fieldHeading}
              </Text>
            ))}
          <Box h="100%" w={24} />
        </HStack>


        {/* Scrollable list area */}
        <Box
          w="100%"
          flex="1"
          overflowY="auto"
          borderWidth="1px"
          borderRadius="md"
          p={2}
          maxH="calc(100vh - 300px)"
        >
          {items
            .filter((item) =>
              columns.some(({ fieldName }) =>
                item[fieldName]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .slice()
            .sort((a, b) => a[columnToCompare].localeCompare(b[columnToCompare]))
            .map((item) => (
              <EditableListItem
                key={item.id}
                columns={columns}
                fields={item}
                onSave={handleSaveItem(item.id)}
                onDelete={handleDeleteItem(item.id)}
              />
          ))}
        </Box>
      </VStack>

      {/* Add New Item Modal */}
      <Modal isOpen={isAddModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent bg="mdm.dark">
          <ModalHeader>Add New Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <VStack spacing={4} onKeyDown={(e) => e.key === 'Enter' && handleAddItem()}>
            {columns
              .map(({ fieldName, fieldHeading, fieldType, choiceInfo }) =>
                fieldType === 'choice' ? (
                  <DynamicSelect
                    key={fieldName}
                    fieldHeading={fieldHeading}
                    value={newFields[fieldName] || ""}
                    choiceInfo={choiceInfo}
                    onChange={(newValue) => {
                      setNewFields((prev) => {
                        const updates = Object.entries(choiceInfo.fieldsMapping).reduce(
                          (acc, [key, mappedField]) => ({
                            ...acc,
                            [key]: newValue[mappedField],
                          }),
                          {}
                        );
                        return { ...prev, ...updates };
                      });
                    }}
                  />
                ) : (
                  <Input
                    key={fieldName}
                    placeholder={fieldHeading}
                    value={newFields[fieldName] || ""}
                    onChange={(e) =>
                      setNewFields((prev) => ({ ...prev, [fieldName]: e.target.value }))
                    }
                  />
                )
              )
            }
          </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleAddItem}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default EditableList;