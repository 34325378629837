// ./components/common/NavGrid.jsx

// React
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Chakra
import { Box, Button, Heading, HStack, SimpleGrid, Text } from '@chakra-ui/react';

const NavGrid = ({ options }) => {
  // ------------------------------ Hooks ------------------------------ //

  const navigate = useNavigate();

  // ------------------------------ Render ------------------------------ //

  return (
    <SimpleGrid w="100%" columns={{ base: 1, md: 2 }} spacing={4}>
      {options.map((option, index) => (
        <Box key={index} p={4} borderWidth="1px" borderRadius="md">
          <HStack>
            {option.icon}
            <Heading size="md">{option.title}</Heading>
          </HStack>
          <Text mt={2}>{option.description}</Text>
          <Button
            mt={4}
            colorScheme="mdm_teal"
            onClick={() => navigate(option.path)}
            isDisabled={option.isDisabled}
          >
            {option.buttonText}
          </Button>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default NavGrid;