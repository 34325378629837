// ./components/Login/Login.jsx

// React
import { useState } from 'react';

// Firebase
import { signInWithEmailAndPassword } from 'firebase/auth';

// Chakra
import {
  Box, VStack, Input, Button, Heading, useToast, Spinner, HStack,
} from '@chakra-ui/react';

// MdM
import { auth } from '../../common/firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect or update state upon successful login
    } catch (err) {
      toast({
        title: 'Login failed',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%">
      <VStack spacing={4} bg="mdm_teal.500" width="fit-content" p="40px" borderRadius={10}>
        <Heading>Login</Heading>
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          width="300px"
          onKeyDown={handleKeyDown}
        />
        <Input
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          width="300px"
          onKeyDown={handleKeyDown}
        />
        <HStack spacing={4} align="center">
          <Button colorScheme="mdm_teal" onClick={handleLogin} isDisabled={isLoading}>
            Login
          </Button>
          {isLoading && <Spinner />}
        </HStack>
      </VStack>
    </Box>
  );
};

export default Login;